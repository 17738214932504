.product-badges {
    @apply flex flex-col gap-y-2.5 absolute top-5 md:top-8 left-0 z-1;
    &-pdp {
        @apply flex flex-row mb-2.5 md:mb-[0.188rem] gap-2;
    }
}
.product-badges,
.product-badges-pdp {
    .product-badge {
        @apply rounded-r-[1.5rem] py-1 px-4 inline-block text-center font-primary font-bold uppercase not-italic leading-[1.125rem] text-[0.563rem] md:text-xs md:leading-[1.125rem] md:rounded-r-[1.25rem] md:text-xs;
        &-out-of-stock,
        &-outofstock {
            @apply bg-grey-7 text-black;
        }
        &-new {
            @apply bg-new-green-1 text-white;
        }
        &-on-sale,
        &-sale {
            @apply bg-red text-white;
        }
        &-ira-eligible {
            @apply bg-blue-3 flex flex-row gap-1.5 text-black;
        }
        &-pre-order,
        &-preorder {
            @apply bg-new-green-5 text-black;
        }
    }
}