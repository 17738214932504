.skip-to-content {
  @apply sr-only;
  &:active,
  &:focus {
      @apply bg-accents-3 text-primary p-2.5 uppercase absolute text-center top-0 left-0 w-full h-auto not-sr-only z-[200];
  }
}

.sidebar {
  height: calc(100vh - 5rem);
  @apply md:h-screen;
}
.auth-modal {
  @apply [&>*:first-child]:max-w-[calc(100%-1.75rem)] xl:[&>*:first-child]:max-w-[56rem] lg:[&>*:first-child]:max-w-[36rem];
}