.internal-only-wrapper {
    @apply hidden lg:flex fixed left-0 top-[9.25rem] w-full max-w-[58.125rem] p-2.5 z-[123333] bg-[#666] transform transition-transform -translate-x-full duration-300;
    &.open {
        @apply translate-x-0;
    }
}
.internal-content-wrapper {
    @apply flex w-full bg-white;
    :global {
        .internal-left {
            @apply p-[0.9375rem] basis-1/4 w-1/4;
            ul {
                @apply text-[0.65625rem] leading-normal;
                li {
                    @apply mb-[0.5625rem] leading-normal;
                    a, .btn-link {
                        @apply underline font-normal text-[#285582] text-[0.65625rem] leading-normal hover:text-blue-1 hover:no-underline;
                    }
                }
            }
        }
        .internal-right {
            @apply p-[0.9375rem] basis-3/4 w-3/4;
            .cms-content {
                @apply max-h-[26.25rem] overflow-y-auto border-l border-solid border-[#ddd] ml-[0.0625rem];
            }
            .items {
                @apply grid-cols-1;
                .productitem-block {
                    div[class*="productimage-block"] img {
                        @apply mb-0;
                    }
                }
            }
        }
        h4 {
            @apply uppercase text-secondary-2;
        }
        ul {
            @apply mb-2.5;
        }
    }
}
.internal-handle-bar {
    @apply h-[1.9375rem] pl-2.5 pr-1.5 bg-[#59b200] absolute top-0 right-0 left-auto select-none transform origin-top-right -rotate-90;
    button {
        @apply text-sm leading-[1.9375rem] text-white font-semibold no-underline hover:no-underline hover:text-white uppercase;
        svg {
            @apply text-white ml-2 mt-1;
        }
    }
}
.sign-in-as-customer-wrapper {
    background-color: rgba(165, 211, 242, 0.10);
    @apply rounded-[0.3125rem] px-[0.5625rem] pt-3.5 pb-[1.8125rem] mb-5 md:pb-[1.9375rem] md:px-5 lg:px-7 md:mb-[1.875rem];
    form {
        @apply flex flex-col gap-y-3 max-w-md mx-auto px-[0.0625rem] md:pr-0 md:mx-0 md:max-w-none md:flex-row md:gap-x-5 md:items-center lg:pl-[0.3125rem];
        :global {
            .form-group {
                @apply mb-0 md:w-1/4 md:max-w-[15.3125rem] md:relative;
                &:first-child {
                    @apply md:w-[30%];
                }
                &:last-child {
                    @apply md:w-1/5 md:max-w-[10.1875rem];
                }
            }
            .form-input {
                @apply appearance-none w-full border border-solid border-grey-1 bg-white text-black py-[0.6875rem] px-4 font-medium text-sm leading-6 touch:text-base mb-0.5 rounded-none focus:shadow-none;

                &::placeholder {
                    @apply text-accents-5;
                }

                &:focus {
                    @apply border-gold-1 outline-2 outline outline-gold-1;
                    &::placeholder {
                        @apply text-accents-5;
                    }
                }

                &.border-alert-danger {
                    @apply border-red outline-none;
                    &::placeholder {
                        @apply text-accents-5;
                    }
                    &:focus {
                        @apply border-red border outline-none;
                        &::placeholder {
                            @apply text-accents-5;
                        }
                    }
                }

                &.disabled, &:disabled {
                    @apply outline-none border-grey-9;
                    &::placeholder {
                        @apply text-grey-9;
                    }
                    &:focus {
                        @apply border border-grey-9 outline-none;
                        &::placeholder {
                            @apply text-grey-9;
                        }
                    }
                }
            }
            button {
                @apply mt-1 md:mt-[1.3125rem];
            }
            .error {
                @apply md:absolute md:-bottom-5 md:left-0 md:whitespace-nowrap;
            }
        }
    }
}
