.product-details {
    max-width: calc(100% - 9.4375rem);
    @apply md:max-w-full;
    :global {
        .product-price {
            @apply flex flex-wrap gap-x-1 md:items-center md:justify-center text-grey-1 text-[0.9375rem] leading-6 font-bold mb-[0.5625rem] md:text-lg md:leading-6 md:mb-4;
            .price-note {
                @apply inline-block align-top mr-1 text-grey-1 capitalize md:block md:w-full text-[0.6875rem] leading-[1.125rem] font-medium -tracking-[0.015625rem] md:mb-[0.0625rem] md:mr-0;
                + strong {
                    @apply -mt-[0.4375rem] md:mt-0;
                    &:has(.non-discount-price) {
                        @apply block;
                    }
                }
            }
            .non-discount-price {
                @apply text-grey-1 mr-1;
            }
            .product-price--value {
                @apply font-medium text-sm leading-6;
                strong {
                    @apply text-[0.9375rem] leading-6 font-bold lg:text-lg lg:leading-6;
                }
            }
        }
        .btn-primary-type-plp {
            @apply flex md:justify-center;
            .btn-primary {
                @apply text-[0.625rem] leading-6 py-0 px-2 max-w-[10rem] h-6 md:h-[2.1875rem] md:px-3 md:text-xs md:leading-[2.1875rem] md:ml-[0.1875rem] flex-grow md:max-w-[11.0625rem];
            }
        }
    }
}

.productimg-link {
    :global {
        div[class*="ProductBadge_product-badges"] {
            &:empty {
                @apply hidden;
            }
        }
    }
}