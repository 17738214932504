.productitem-block {
    @apply border-b border-solid border-[#ddd];
    &:last-child {
        @apply border-b-0;
    }
}
.productitem-inner-wrapper {
    @apply p-2.5 h-[4.375rem] relative;
}
.productimage-block {
    @apply min-h-[3.125rem] max-w-[3.125rem] max-h-[3.125rem];
}
.product-details {
    @apply absolute top-0 left-0 inline-block w-full p-0 text-center;
}
.product-name {
    -webkit-hyphens: none;
    hyphens: none;
    @apply m-0 inline-block w-[65%] font-primary font-normal tracking-normal text-secondary-2 text-[0.65625rem] leading-[4.4375rem] text-left truncate normal-case;
    .productname-link {
        @apply text-secondary-2 text-[0.65625rem] leading-[4.4375rem] underline hover:no-underline;
    }
}
.product-atc-btn {
    > div {
        @apply w-auto top-1/4 right-[0.9375rem] left-auto bottom-auto mt-[0.3125rem] inline-flex justify-end items-center;
    }
    :global {
        .btn-quickAdd, html & .btn-primary {
            @apply whitespace-nowrap py-0 px-2.5 h-8 text-[0.4375rem] leading-[1.875rem] font-normal font-primary uppercase rounded-none transform transition-all duration-300 text-secondary-2 bg-white border border-solid border-[#ccc] hover:text-white hover:border-[#285582] hover:bg-[#285582];
            &.disabled, &[disabled] {
                @apply cursor-default pointer-events-none text-[0.625rem] border-0 bg-white hover:bg-white hover:text-secondary-2;
            }
        }
        .out-of-stock {
            @apply cursor-default pointer-events-none absolute w-auto top-1/4 right-[0.9375rem] left-auto bottom-auto mt-[0.3125rem] inline-block whitespace-nowrap py-0 px-2.5 h-8 text-[0.4375rem] leading-[1.875rem] font-normal font-primary uppercase text-secondary-2 bg-white border border-solid border-transparent;
        }
    }
}
