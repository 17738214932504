.root {
    @apply relative top-0 left-0 w-full z-50 animate-none;
    &.shadow-magical {
        @apply sticky;
        animation: slide-down 350ms ease-in;
        .nav-container {
            @apply lg:py-0 lg:px-5 xl:pl-10 xl:pr-[3.25rem];
        }
        .nav-section {
            @apply lg:justify-between lg:gap-x-5 2xl:gap-x-4;
        }
        .megamenu-cms {
            @apply lg:block lg:flex-grow 2xl:pl-[4.6875rem];
            &-default {
                @apply lg:hidden;
            }
            > nav {
                @apply px-0;
            }
        }
        .contact-us-block {
            @apply lg:hidden;
        }
        .logo {
            @apply lg:ml-0;
        }
        .search-display {
            @apply lg:pt-[0.4375rem] lg:pb-8;
        }
        :global {
            .contact-menu {
                @apply lg:block;
                .header-contact-us-links {
                    @apply flex flex-wrap items-center leading-[0.875rem] lg:gap-x-4 xl:gap-x-7;
                    li {
                        a {
                            @apply inline-flex items-center gap-x-1.5 text-white text-[0.6875rem] leading-[0.875rem] tracking-[0.03125rem] uppercase font-normal no-underline hover:text-gold-2;
                        }
                        &.phone-link {
                            a {
                                span {
                                    &:not(.mobile-only) {
                                        @apply lg:hidden xl:inline #{!important};
                                    }
                                }
                            }
                        }
                        &.chat-link {
                            a {
                                span {
                                    @apply hidden;
                                }
                            }
                        }
                    }
                }
            }
            ul[class *= "UserNav_list"] {
                @apply lg:gap-x-4 xl:gap-x-7;
                li {
                    span[aria-hidden] {
                        @apply mr-0;
                    }
                    span[class *= "-label"] {
                        @apply hidden;
                    }
                    .btn-link {
                        &:hover {
                            &:after {
                                @apply hidden;
                            }
                        }
                    }
                    &.cart-menu {
                        .cart-count {
                            @apply absolute -top-[0.5625rem] -right-1.5 bg-red rounded-full px-[0.222rem] text-[0.6875rem] leading-[0.875rem] font-semibold tracking-[0.03125rem] text-white uppercase;
                            &:before, &:after {
                                @apply hidden;
                            }
                            &:not(.has-count) {
                                @apply hidden;
                            }
                        }
                    }
                    &.search-menu {
                        .btn-link {
                            &:after {
                                @apply lg:block lg:-bottom-1 lg:left-0 lg:-right-2;
                            }
                        }
                    }
                }
            }
            .MenuWrapper {
                @apply lg:justify-start lg:gap-x-3 xl:gap-x-6 2xl:gap-x-[1.875rem];
            }
            .menu-item.level-1 {
                > div:not(.subCat) {
                    a {
                        @apply text-white pt-[1.9375rem] pb-7;
                        &:after {
                            @apply bottom-[1.4375rem] border-gold-2;
                        }
                    }
                }
                &.active, &:hover {
                    > div:not(.subCat) {
                        a {
                            @apply text-gold-2;
                        }
                    }
                }
                &:nth-child(2) {
                    @apply lg:mr-3 xl:mr-6 2xl:mr-[1.875rem];
                    > div:not(.subCat) {
                        &:after {
                            @apply bg-white lg:mt-2.5 lg:-right-3 xl:-right-6 2xl:-right-[1.875rem];
                        }
                    }
                }
            }
        }
    }
}
.nav-wrapper {
    @apply relative bg-blue-1 w-full z-50;
}
.contact-us-block {
    @apply hidden lg:block lg:mt-2 lg:flex-grow lg:max-w-[11.8125rem];
    :global {
        .header-contact-us-links {
            @apply flex flex-wrap items-center lg:gap-x-4;
            li {
                a {
                    @apply inline-flex items-center gap-x-1.5 text-white text-xs leading-[0.875rem] tracking-[0.03125rem] pb-[0.0625rem] font-bold no-underline border-b border-solid border-transparent hover:text-gold-2 hover:border-gold-2;
                }
                &.phone-link {
                    a {
                        @apply pb-0.5;
                    }
                }
                &.chat-link {
                    a {
                        @apply leading-[1.125rem] gap-x-2 -tracking-[0.015625rem];
                    }
                }
            }
        }
    }
}
.view-chart-banner {
    @apply bg-black w-full h-[2.0625rem] lg:h-[2rem] overflow-hidden;
    :global {
        div[class *= "row_contained"] {
            @apply max-w-[69.375rem] px-[0.9375rem] lg:px-5;
        }
        .header-panel {
            @apply flex items-start lg:items-center justify-between;
        }
        .view-chart-link {
            a {
                @apply inline-flex items-center gap-x-1 uppercase font-extrabold text-white no-underline text-[0.6875rem] leading-[2.0625rem] lg:leading-8 hover:no-underline;
                svg {
                    @apply text-[#d3c272] w-3 h-3;
                }
                &:hover {
                    span {
                        @apply underline;
                    }
                }
                .hide-on-mobile {
                    @apply hidden sm:inline;
                }
            }
        }
        .chart-price-list {
            @apply flex flex-col lg:flex-row lg:gap-[2.125rem];
            li {
                @apply inline-flex items-center text-white gap-[0.1875rem] text-[0.6875rem] leading-[2.0625rem] font-extrabold uppercase lg:leading-8;
                span {
                    @apply font-medium;
                }
            }
        }
        .caret-up, .caret-down {
            @apply border-[0.1875rem] border-solid border-transparent;
        }
        .caret-up {
            @apply border-b-[#59b200];
        }
        .caret-down {
            @apply border-t-[#ff2626] mt-[0.3125rem];
        }
    }
}
.megamenu-cms {
    @apply hidden lg:min-h-[4.5625rem];
    &-default {
        @apply lg:relative lg:z-10 lg:min-h-[2.5625rem];
        @screen lg {
            background-color: rgb(165 211 242 / 30%);
            box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
        }
    }
}

.nav-container {
    @apply pt-4 pb-[0.6875rem] px-[1.875rem] lg:px-5 lg:pt-[1.125rem] lg:pb-4 xl:pl-8 xl:pr-10 max-w-[90rem] mx-auto;
    &-inner {
        @apply flex;
    }
}
.nav-section {
    @apply flex items-center justify-between w-full;
}
.search-display {
    @apply animate-none relative flex flex-row pt-0.5 pb-[1.3125rem] pl-[2.375rem] pr-[1.875rem] lg:px-0 lg:pt-[1.125rem] lg:pb-[1.375rem] w-full bg-white z-[9];
    box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25);
    @screen lg {
        box-shadow: 0 0.25rem 1.6875rem 0 rgba(0, 0, 0, 0.15);
    }
    form {
        @apply w-full lg:max-w-[41.875rem] lg:mx-auto;
    }
}
.toggle-search {
    @apply hidden;
}

.logo {
    @apply ml-[1.8125rem] lg:ml-[4.6875rem];
    svg {
        @apply cursor-pointer w-[8.625rem] h-[1.875rem] lg:w-[11.125rem] lg:h-[2.375rem];
    }
}

@keyframes slide-down {
    0% {
        -webkit-transform: translate3d(0, -5rem, 0);
        transform: translate3d(0, -5rem, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}